import React, { useState } from "react";
import { Alert, Dropdown, Input } from "../../formGroup/Index";
import axios from "axios";
export default function Edit(props) {
  console.log(props.editFaculty);
  const [name, setName] = useState(props.editFaculty.name);
  const [email, setEmail] = useState(props.editFaculty.email);
  const [mobileNo, setMobileNo] = useState(props.editFaculty.mobileNo);
  const [profileLink, setprofileLink] = useState(props.editFaculty.profileLink);
  const [orcidId, setOrcidId] = useState(props.editFaculty.orcidId);
  const [scopusId, setScopusId] = useState(props.editFaculty.scopusId);
  const [designation, setDesignation] = useState(props.editFaculty.designation);
  const [title, setTitle] = useState(props.editFaculty.title);

  const editUser = () => {
    axios
      .put(`/api/v1/users/${props.editFaculty.id}`, {
        title,
        name,
        email,
        mobileNo,
        profileLink,
        orcidId,
        scopusId,
        designation,
      })
      .then(function (response) {
        // console.log(response);
        alert(response.data.message);
        props.setShowModal(false);
        props.updateData();
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.errors);
      });
  };

  return (
    <>
      {props.showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {props.editFaculty.name}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="col-span-full">

                    <div className="grid md:grid-cols-3 gap-x-4 gap-y-2 ">
                    <div>
                                    <label className='block mb-2 text-sm font-medium text-gray-900 '>Tittle<span className='text-red-500 font-bold text-[1.2rem]'>*</span></label>
                                    <select  value={title} onChange={(e) => { setTitle(e.target.value) }} id="category" class={`w-full mt-2 h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200`}>
                                        <option className="" value=''>-----Select-----</option>
                                        <option value="श्री">श्री</option>
<option value="सुश्रीमती">सुश्रीमती</option>
<option value="कुमारी">कुमारी</option>
<option value="प्रो.">प्रो.</option>

                                            {/* <option className="" value='1'>Admin</option> */}
                                            <option className="" value='डॉ.'>डॉ.</option>

                                    </select>
                                    </div>
                      <Input
                        label="Name"
                        name="name"
                        man="*"
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        placeholder=""
                      />
                      <Input
                        label="Email"
                        name="email"
                        man="*"
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder=""
                      />
                      <Input
                        label="Mobile No"
                        name="mobileNo"
                        man="*"
                        type="number"
                        value={mobileNo}
                        onChange={(e) => {
                          setMobileNo(e.target.value);
                        }}
                        placeholder=""
                      />
                      <Input
                        label="Designation"
                        man="*"
                        name="designation"
                        type="text"
                        value={designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                        placeholder=""
                      />
                    </div>
                    <div className="col-span-full">
                      <div className="grid md:grid-cols-3 gap-x-4 gap-y-2">
                        <Input
                          label="Profile link"
                          name="profileLink"

                          type="text"
                          value={profileLink}
                          onChange={(e) => {
                            setprofileLink(e.target.value);
                          }}
                          placeholder=""
                        />
                        <Input
                          label="Orcid ID"
                          name="orcidId"

                          type="text"
                          value={orcidId}
                          onChange={(e) => {
                            setOrcidId(e.target.value);
                          }}
                          placeholder=""
                        />
                        <Input
                          label="Scopus Id"
                          name="scopusId"

                          type="text"
                          value={scopusId}
                          onChange={(e) => {
                            setScopusId(e.target.value);
                          }}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => editUser()}
                    // onClick={() => props.setShowModal(false)}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
